* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

nav {
  height: 72px;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  justify-content: space-between;
}

nav ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}

nav ul li button {
  padding: 8px 20px 8px 20px;
  border-radius: 5px;

}

nav ul li {
  list-style: none;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

nav ul select {
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.loginbtn {
  background-color: rgba(49, 237, 49, 1);
  font-size: 16px;
  font-weight: 400;
  color: white;
  border: 1px solid rgba(49, 237, 49, 1);

}

.locationbtn {
  border: 1px solid rgba(207, 207, 207, 1);
  background: none;
  font-size: 16px;
  font-weight: 400;
}

.banner {
  background-image: url(./Assets/Rectangle\ 37.svg);
  /* padding: 200px 50px; */
  padding: 40px 40px;
  background-position: center;
  background-size: cover;

}

.banner h2 {
  font-family: "Oxanium", sans-serif;
  font-size: 61px;
  font-weight: 800;
  color: white;
}

.banner p {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: white;
}

.opac {
  background-color: rgba(255, 255, 255, 0.4);
  height: 144px;
  width: 70%;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
  gap: 50px;
}

.opacinput {
  height: 72px;
  background-color: white;
  width: 80%;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  gap: 30px;

}

.opacinput i {
  font-size: 24px;
}

.opacinput input {
  width: 100%;
  height: 100%;
  border: none;
  background: none;

}

.opac button {
  width: 180px;
  height: 72px;
  background-color: rgba(49, 237, 49, 1);
  color: white;
  border-radius: 12px;
  border: none;
}

.cat-div {
  padding: 50px;
}

.cat-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
}

.cat-heading button {
  Width:
    207px;
  Height:
    58px;
  background-color: rgba(49, 237, 49, 1);
  border-radius: 12px;
  border: none;
  font-family: "Oxanium", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: white;
}

.cat-heading p {
  font-family: "Oxanium", sans-serif;
  font-size: 28px;
  font-weight: 600;
}

.car-card-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  Width: 270px;
  Height: 90px;
  background-color: rgba(235, 238, 243, 1);
}

.car-card-heading p {
  font-family: "Oxanium", sans-serif;
  font-size: 37px;
  font-weight: 800;
}

.car-card {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
}

.car-card img {
  width: 90%;
  /* width: 100%; */
}

.car-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 20px;
  flex-wrap: wrap;
}

.AvailableTurf-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.AvailableTurf-heading h3 {
  font-family: "Oxanium", sans-serif;
  font-size: 28px;
  font-weight: 600;
}

.AvailableTurf-heading p {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.turf-banner {
  height: 350px;
  background-color: rgba(235, 238, 243, 1);
  width: 100%;
  border-radius: 6px;
}

.turf-banner-div {
  Height: 350px;
  background-image: url(./Assets/Group\ 1000005977.svg);
  background-size: cover;
  color: white;
  padding: 30px;
}

.turf-banner-div h2 {
  font-family: "Oxanium", sans-serif;
  font-size: 36px;
  font-weight: 700;
}

.turf-banner-div p {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.turf-banner-div h4 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.turf-banner-div button {
  float: inline-end;
  height: 58px;
  width: 180px;
  border-radius: 12px;
  background-color: rgba(49, 237, 49, 1);
  border: none;
  font-family: "Oxanium", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: white;
  margin-top: 15px;

}

.game-cards-div {
  padding: 50px 20px;

}

.game-cards-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.game-cards-heading h3 {
  font-family: "Oxanium", sans-serif;
  font-size: 28px;
  font-weight: 600;
}

.game-cards-heading p {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.game-cards {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  overflow-x: auto;
  justify-content: flex-start;
}

.game-card {
  Width: 370px;
  Height: auto;
  border-radius: 10px;
}

@media (max-width: 1200px) {
  .game-card {
    Width: 360px;
    /* Smaller size for large tablets */
  }
}

@media (max-width: 768px) {
  .game-card {
    Width: 340px;
    /* Smaller size for medium tablets */
  }
}

@media (max-width: 480px) {
  .game-card {
    Width: 320px;
    /* Smaller size for mobile devices */
  }
}

.game-card-img {
  height: 370px;
  height: 241px;

  border-radius: 10px;
  background-image: url(./Assets/Rectangle\ 21.svg);
}

.game-card h3 {
  font-family: "Oxanium", sans-serif;
  font-size: 23px;
  font-weight: 600;
}

.game-card p {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  margin-top: 10px;

}

.game-card h4 {
  font-family: "Oxanium", sans-serif;
  font-size: 19px;
  font-weight: 600;
  margin-top: 10px;

}

.game-card button {
  width: 100%;
  height: 58px;
  border-radius: 12px;
  background-color: rgba(49, 237, 49, 1);
  color: white;
  font-family: "Oxanium", sans-serif;
  font-size: 18px;
  font-weight: 700;
  border: 1px solid rgba(49, 237, 49, 1);
  margin-top: 10px;
}

.review-cards {
  display: flex;
  overflow-x: auto;
  gap: 16px;
  /* overflow-x: auto; */
}

.venue-title {
  display: flex;
  justify-content: space-between;
}

.venue-title h5 {
  font-family: "Oxanium", sans-serif;
  font-size: 23px;
  font-weight: 600;
}

.venue-title h6 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.reviews h2 {
  font-family: "Oxanium", sans-serif;
  font-size: 28px;
  font-weight: 600;
}

.review-card {
  border: 1px solid rgba(38, 38, 38, 1);
  height: auto;
  width: 310px;
  min-width: 310px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 30px;
}

.reviw-profile-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.star {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.review-card h3 {
  font-family: "Oxanium", sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.review-card p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: rgba(105, 104, 104, 1);
}

.resister {

  padding: 50px;
}

.resister-div {
  background-color: rgba(235, 238, 243, 1);
  border-radius: 6px;
  display: flex;
  justify-content: space-between;

}

.resister-div-left h2 {
  font-family: "Oxanium", sans-serif;
  font-size: 60px;
  font-weight: 700;
}

.resister-div-left button {
  Width:
    179.24px;
  Height:
    58px;
  background-color: rgba(49, 237, 49, 1);
  color: white;
  font-family: "Oxanium", sans-serif;
  font-size: 18px;
  font-weight: 700;
  border-radius: 12px;
  border: none;
}

.resister-div-left {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: center;
  padding-left: 50px;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.foot-social {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.footer ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.footer ul li {
  list-style: none;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;

}

.reg-banner {
  padding: 30px;
}

.reg-banner-div {
  background-image: url(./Assets/Group\ 1000005979.svg);
  padding-top: 150px;
  padding-bottom: 150px;
  background-size: cover;
  padding-left: 50px;
  color: white;
}

.reg-banner-div h2 {
  font-family: "Oxanium", sans-serif;
  font-size: 57px;
  font-weight: 700;
}

.reg-banner-div h2 span {
  font-size: 40px;
  font-weight: 600;
}

.reg-banner-div button {
  Width:
    179.24px;
  Height:
    58px;
  background-color: rgba(49, 237, 49, 1);
  color: white;
  border: none;
  font-family: "Oxanium", sans-serif;
  font-size: 18px;
  font-weight: 700;
  border-radius: 12px;
}

.reg-banner-div {
  border-radius: 28px;
}

.available-venues-heading {}

.available-venues-heading h2 {
  font-family: "Oxanium", sans-serif;
  font-size: 28px;
  font-weight: 600;
}

.available-venues {
  padding: 50px;
}

.reg-banner-div2 {
  background-image: url(./Assets/image\ 6.svg);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 230px;
  padding-bottom: 230px;
  background-position: right;
}

.venue-detail {
  padding: 50px;
}

.venue-info {
  width: 100%;
  background-color: rgba(243, 245, 248, 1);

}

.venue-info img {
  width: 100%;
}

.venue-text {
  padding: 30px;
}

.green-review {
  background-color: rgba(26, 182, 79, 1);
  color: white;
  border-radius: 3px;
  Width:61.13px;
  Height:25.17px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.green-review p {
  font-family: "Oxanium", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.venue-text h2 {
  font-family: "Oxanium", sans-serif;
  font-size: 32px;
  font-weight: 700;
}

.venue-text-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.venue-text-heading h4 {
  font-family: "Oxanium", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

venue-text-heading2 {
  display: flex;
  flex-direction: row; /* Aligns content horizontally */
  align-items: center; /* Aligns items vertically in the center */
  justify-content: space-between; /* Ensures space between text and button */
  gap: 20px;
}

.venue-text-heading2-text {
  flex: 1; /* Allows text to take available space */
}

.venue-text-heading2 p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.venue-text-heading2 button {
  /* Width: 179.24px;
  Height: 58px; */
  margin-left: auto;
  width: 25%;
  height: 60px;
  background-color: rgba(49, 237, 49, 1);
  color: white;
  border: none;
  font-family: "Oxanium", sans-serif;
  font-size: 16px;
  font-weight: 700;
  border-radius: 12px;
  display: block;
  margin: 10px auto;
}


@media (max-width: 768px) {
  .venue-text-heading2 button {
    width: 60%;
    font-size: 14px;
    height: 55px;
  }
}

@media (max-width: 480px) {
  .venue-text-heading2 button {
    width: 80%;
    font-size: 12px;
    height: 45px;
  }
}

@media (max-width: 320px) {
  .venue-text-heading2 button {
    width: 90%;
    font-size: 10px;
    height: 40px;
  }
}


.venue-text-heading2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.venue-text-heading3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.venue-text-heading3 h3 {
  font-family: "Oxanium", sans-serif;
  font-size: 24px;
  font-weight: 600;
}

.venue-text-heading3 p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.tab-container {
  padding: 50px;
}

.tab-container2 {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 5px;
}

.tabs {
  background-color: rgba(235, 238, 243, 1);
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  overflow-x: auto;
}

.tabs button {
  background: none;
  border: none;
  font-family: "Oxanium", sans-serif;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 15px;
}

@media (max-width: 768px) {
  .tabs button {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .tabs button {
    font-size: 11px;
  }
}

@media (max-width: 320px) {
  .tabs button {
    font-size: 10px;
  }
}

.tab1 p {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.tab1 ul li {
  font-family: "Roboto", sans-serif;
  font-size: 400px;
  font-weight: 400;
}

@media (max-width: 1200px) {
  .tab1 p {
    font-size: 18px;
  }

  .tab1 ul li {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .tab1 p {
    font-size: 16px;
  }

  .tab1 ul li {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .tab1 p {
    font-size: 14px;
  }

  .tab1 ul li {
    font-size: 14px;
  }
}


.Availablesport-btn {
  display: flex;
  gap: 20px;
}

.Availablesport-btn button {
  Width:
    230px;
  Height:
    60px;
  border: 1px solid black;
  border-radius: 44px;
  font-family: "Oxanium", sans-serif;
  font-size: 20px;
  font-weight: 600;
  background: none;

}

/* .Amenities-btn {
  display: flex;
  gap: 20px;
}

.Amenities-btn button {
  Width:
    300px;
  Height:
    120px;
  border: 1px solid black;
  border-radius: 44px;
  font-family: "Oxanium", sans-serif;
  font-size: 20px;
  font-weight: 600;
  background: none;

} */

.Amenities-btn {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.Amenities-btn button {
  width: 300px;
  height: 120px;
  border: 1px solid black;
  border-radius: 44px;
  font-family: "Oxanium", sans-serif;
  font-size: 20px;
  font-weight: 600;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Amenities-btn button img {
  height: 60px;
  width: 60px;
  object-fit: contain;
}

@media (max-width: 1200px) {
  .Amenities-btn button {
    width: 250px;
    height: 110px;
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .Amenities-btn {
    gap: 15px;
  }

  .Amenities-btn button {
    width: 45%;
    height: 100px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .Amenities-btn {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .Amenities-btn button {
    width: 90%;
    height: 90px;
    font-size: 14px;
    border-radius: 30px;
  }

  .Amenities-btn button img {
    height: 40px;
    width: 40px;
  }
}


.tab1 h3 {
  font-family: "Oxanium", sans-serif;
  font-size: 28px;
  font-weight: 600;
}

.tab1 ul li {
  font-family: "Oxanium", sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 15px;
}

.sloat-heading-div {
  padding: 50px;

}

.sloat-heading {
  background-color: rgba(243, 245, 248, 1);
  border-radius: 0px 0px 20px 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.sloat-heading1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.sloat-heading1 h2 {
  font-family: "Oxanium", sans-serif;
  font-size: 32px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.sloat-heading1 h4 {
  font-family: "Oxanium", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.sloat-heading p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: center;

}

.calander-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.calander-heading p {
  font-family: "Oxanium", sans-serif;
  font-size: 32px;
  font-weight: 600;
}

@media (max-width: 1200px) {
  .calander-heading p {
    font-size: 26px;
  }
}

@media (max-width: 768px) {
  .calander-heading p {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .calander-heading p {
    font-size: 16px;
  }
}

.slot-dates {}

.slot-dates-div {
  Width:
    105px;
  Height:
    94px;
  background-color: rgba(238, 238, 238, 1);
  border-radius: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 5px;
}

.slot-dates-div p {
  font-family: "Oxanium", sans-serif;
  font-size: 24px;
  font-weight: 500;
}

.slot-dates {
  display: flex;
  flex-direction: column;
  padding: 30px;
  overflow-x: auto;
}

.NaturalGrass {
  padding-bottom: 30px;
  border-bottom: 4px solid rgba(217, 217, 217, 1);
}

.NaturalGrass p {
  font-family: "Oxanium", sans-serif;
  font-size: 32px;
  font-weight: 600;
}

.NaturalGrass-div {
  padding: 50px;
}

.slot-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 50px;
}

.slot-heading h3 {
  font-family: "Oxanium", sans-serif;
  font-size: 32px;
  font-weight: 600;
}

.slot-heading p {
  font-family: "Oxanium", sans-serif;
  font-size: 26px;
  font-weight: 400;
}

.day-night-btn {
  padding: 0px 50px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.day {
  background-color: rgba(252, 245, 221, 1);
  border-radius: 51px;
  padding: 15px 30px;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: "Oxanium", sans-serif;
  font-size: 24px;
  font-weight: 600;
}

.Mid-Night {
  background-color: rgba(5, 10, 48, 1);
  border-radius: 51px;
  padding: 15px 30px;

  border: none;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: "Oxanium", sans-serif;
  font-size: 20px;
  font-weight: 600;
}

/* .time-divs {
  border: 1px solid rgba(49, 237, 49, 1);
  background-color: rgba(245, 254, 245, 1);
  border-radius: 8px;
  padding: 20px 30px;
}

.time-div {
  padding-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
}

.time-divs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.time-text {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.time-text p {
  font-family: "Oxanium", sans-serif;
  font-size: 24px;
  font-weight: 500;
} */

.time-divs {
  border: 1px solid rgba(49, 237, 49, 1);
  background-color: rgba(245, 254, 245, 1);
  border-radius: 8px;
  padding: 20px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.time-div {
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.time-text {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.status-icon {
  width: 25px;
  height: 25px;
}

.time-text p {
  font-family: "Oxanium", sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}


.SelectedSlot button {
  Width: 130px;
  Height: 55px;
  background-color: rgba(49, 237, 49, 1);
  color: white;
  border: none;
  font-family: "Oxanium", sans-serif;
  font-size: 18px;
  font-weight: 700;
  border-radius: 12px;
}


@media (max-width: 768px) {
  .time-divs {
    padding: 15px 10px;
  }

  .time-text p {
    font-size: 18px;
  }

  .SelectedSlot button {
    width: 110px;
    height: 45px;
    font-size: 16px;
  }

  .status-icon {
    width: 22px;
    height: 22px;
  }
}

@media (max-width: 480px) {
  .time-divs {
    padding: 10px 5px;
  }

  .time-text {
    gap: 10px;
  }

  .time-text p {
    font-size: 12px;
    margin-top: 2px;
  }

  .SelectedSlot button {
    width: 80px;
    height: 35px;
    font-size: 12px;
  }

  .status-icon {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 320px) {
  .time-divs {
    padding: 5px 5px;
  }

  .time-text p {
    font-size: 10px;
    margin-top: 2px;
  }

  .SelectedSlot button {
    width: 80px;
    height: 35px;
    font-size: 12px;
  }

  .status-icon {
    width: 16px;
    height: 16px;
  }
}


.slotdetail {
  background-color: rgba(243, 245, 248, 1);
  padding: 25px;
  width: 90%;
  border-radius: 30px;

}

.slotdetail-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slotdetail-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.slotdetail-heading h3 {
  font-family: "Oxanium", sans-serif;
  font-size: 32px;
  font-weight: 600;
}

.slotdetail-heading h4 {
  font-family: "Oxanium", sans-serif;
  font-size: 24px;
  font-weight: 600;
}

.slotdetail p {
  font-family: "Oxanium", sans-serif;
  font-size: 24px;
  font-weight: 500;
}

.slot-card {
  background-color: rgba(255, 255, 255, 1);
  Width:
    407px;
  Height:
    196px;
  border-radius: 30px;
  padding: 20px;

}

.slot-card-heading {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.slot-card-heading h4 {
  font-family: "Oxanium", sans-serif;
  font-size: 24px;
  font-weight: 400;
}

.slot-card-heading h3 {
  font-family: "Oxanium", sans-serif;
  font-size: 24px;
  font-weight: 600;
}

.apply-coupon h2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

}

.apply-coupon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.apply-coupon h2 {
  font-family: "Oxanium", sans-serif;
  font-size: 32px;
  font-weight: 600;
}

.apply-coupon h2 span {
  font-weight: 400;
  font-size: 24px;

}

.slotdetail-div2 {}

.apply-coupon2 {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.slotdetail2 {
  background-color: rgba(217, 217, 217, 0.1) !important;
  border: 1px solid rgba(192, 192, 192, 1);
  margin-left: 20px;

}

.apply-coupon2 ul {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.apply-coupon2 ul li {
  list-style: none;
  font-family: "Oxanium", sans-serif;
  font-size: 24px;
  font-weight: 400;
}

.cat {
  font-family: "Oxanium", sans-serif;
  font-size: 24px;
  font-weight: 600 !important;
}

.line {
  height: 1px;
  background-color: rgba(225, 225, 225, 1);
  width: 100%;
}

.venue-text-heading2-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

}

.venue-text-heading2-text p {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

}

.venue-text-heading2-text p img {
  height: 20px;
  width: 20px;
}

.coach-avail-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 0px 50px;

}

.coach-avail-heading h2 {
  font-family: "Oxanium", sans-serif;
  font-size: 24px;
  font-weight: 400;
}

.line2 {
  padding: 0px 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}

.line2 img {
  width: 100%;
}

.book-slot-coach {
  padding: 50px;
}

.book-slot-coach-div {
  background-color: rgba(217, 217, 217, 0.1);
  height: 110px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.book-slot-coach-div p {
  font-size: 24px;
  font-weight: 500;
  font-family: "Oxanium", sans-serif;

}

.book-slot-coach-div-p {
  display: flex;
  flex-direction: row;
  gap: 30px;

}

.book-slot-coach-div button {
  border: 1px solid rgba(5, 10, 48, 1);
  border-radius: 6px;
  background: none;
  padding: 10px 20px;
  font-size: 22px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;

}

.addturf-div {
  padding: 50px;
}

.addturf {
  background-color: rgba(235, 238, 243, 1);
  border-radius: 16px;
  padding: 30px;
}

.addturf h1 {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  font-family: "Oxanium", sans-serif;
}

.addtruf-ip {
  display: flex;
  flex-direction: column;
  width: 100%;

}

.addtruf-ip label {
  font-size: 16px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;

}

.addtruf-ip input {
  border-radius: 6px;
  border: none;
  background-color: white;
  height: 47px;
  color: rgba(61, 61, 61, 1);
  font-size: 13px;
  font-weight: 400;
  padding-left: 20px;
  width: 100%;
}

.addtruf-ip select {
  border-radius: 6px;
  border: none;
  background-color: white;
  height: 47px;
  color: rgba(61, 61, 61, 1);
  font-size: 13px;
  font-weight: 400;
  padding-left: 20px;
  width: 100%;
}

.addtruf-ips {
  display: flex;
  flex-direction: row;
  gap: 50px;
  width: 100%;

}

.center-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-btn button {
  background-color: #31ED31;
  border: none;
  border-radius: 5px;
  padding: 10px 25px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;

}

.slot-info {
  border-radius: 8px;
  background-color: white;
  padding: 20px;
}

.slot-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.slot-info1 {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.slot-info3 {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.slot-info3 button {
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  color: white;
  background-color: #31ED31;
}

/* .delete-btn {
  background-color: #FF595E !important;
} */

.delete-btn button {
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
}

.delete-btn button:hover {
  background-color: darkred;
}

.slot-info1 p, .slot-info2 h3 {
  font-size: 24px;
  font-weight: 500;
  font-family: "Oxanium", sans-serif;

}

.profile {
  display: flex;
  flex-direction: row;
  padding: 50px;
  gap: 20px;
}

.newprofile-tabs {
  display: flex;
  flex-direction: column;
}

.profile-left {
  width: 30%;


}

.profile-right {
  width: 70%;
}

.newprofile-tabs {
  width: 90%;
  background-color: rgba(243, 245, 248, 1);
  border-radius: 12px;
  padding: 15px;
}

.newprofile-tabs button {
  border: none;
  background: none;

}

.newprofile-tabs img {
  width: 100%;
}

.newprofile-tabs button {
  text-align: start;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;

}

.pr-box1, .pr-box2 {
  background-color: rgba(243, 245, 248, 1);
  border-radius: 12px;
  padding: 15px;
}

.pro-1 {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.pr-box1 {
  width: 60%;
}

.pr-box1 img {
  width: 100%;
}

.pr-box2 {
  width: 40%;
}

.profile-user-name {
  background-color: #31ED31;
  height: 47px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 12px;

}

.profile-user-name p {
  font-size: 20px;
  font-weight: 500;
  color: white;
  font-family: "Oxanium", sans-serif;


}

.profile-persent {
  background-color: white;
  border-radius: 50%;
  Width:
    144px;
  Height:
    144px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.profile-persent-div {
  Width:
    106px;
  Height:
    106px;
  background: linear-gradient(180deg, #C40994 0%, #341FE3 100%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.pr-box2 {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.pr-box2 p {
  font-size: 16px;
  font-weight: 500;

  font-family: "Oxanium", sans-serif;
  display: flex;

}

.profile-persent-div p {
  color: white;
  font-size: 31px;
  font-weight: 500;
}

.pro-2 {
  background-color: rgba(243, 245, 248, 1);
  border-radius: 12px;
  padding: 20px;

}

.pro-2 h3 {
  font-size: 20px;
  font-weight: 500;

  font-family: "Oxanium", sans-serif;
}

.profile-name-div {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(229, 229, 229, 1);
}

.profile-name-div p {
  width: 30%;
  font-size: 16px;
  font-weight: 400;

  font-family: "Oxanium", sans-serif;
  margin-bottom: 10px;
  margin-top: 10px;

}

.mybooking-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mybooking-heading h3 {
  font-size: 20px;
  font-weight: 500;

  font-family: "Oxanium", sans-serif;
}

.mybooking-heading select {
  padding: 5px;
  border-radius: 5px;
}

.all-bookings {
  background-color: rgba(243, 245, 248, 1);
  border-radius: 16px;
  padding: 20px;
}

.all-bookingsimg {
  width: 220px;
  height: 200px;
}

@media (max-width: 480px) {
  .all-bookingsimg {
    width: 290px;
  }
}





.all-bookings p {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 13px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;

}

.all-bookings h3 {
  font-size: 20px;
  font-weight: 700;

  font-family: "Oxanium", sans-serif;
}

.all-bookings h4 {
  font-size: 16px;
  font-weight: 600;

  font-family: "Oxanium", sans-serif;
}

.booking-timing {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.booking-text {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.all-bookings {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.booking-CRUD-btns {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-top: 10px;
}

.booking-CRUD-btns button {
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  color: white;
  background-color: #31ED31;
  font-size: 13px;
  font-weight: 400;

  font-family: "Oxanium", sans-serif;
}

.login-tebs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-teb {
  border: 1px solid rgba(49, 237, 49, 1);
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;

}

.login-teb button {
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #fff;
  border: none;
  font-size: 20px;
  font-weight: 400;

  font-family: "Oxanium", sans-serif;

}

.tab-btn:hover {
  background-color: #31ED31;
  color: white;
}

.tab-btn.active {
  background-color: #31ED31;
  color: white;

}

.login-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 50px;
  gap: 50px;

}

.login-left, .login-right {
  width: 50%;
  height: 100%;

}

.login-left img {
  width: 100%;
  height: 100%;
}

.login-right-div {
  background-color: rgba(235, 238, 243, 1);
  padding: 100px 70px;
  border-radius: 16px;
}

.login-right-div h3 {
  text-align: center;
  font-size: 40px;
  font-weight: 700;

  font-family: "Oxanium", sans-serif;
}

.login-right-div label {
  font-size: 16px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;


}

.login-right-div input {
  height: 47px;
  border-radius: 6px;
  width: 100%;
  border: none;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 15px;
}

.login-right-div button {
  background-color: rgba(235, 238, 243, 1);
  /* color: white; */
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;

  font-family: "Oxanium", sans-serif;
}

.login-right-div p {
  text-align: center;

  font-size: 16px;
  font-weight: 500;

  font-family: "Oxanium", sans-serif;
}

.login-right-div h4 {
  text-align: center;

  font-size: 16px;
  font-weight: 400;
  color: rgba(241, 90, 93, 1);
  font-family: "Oxanium", sans-serif;
}

.none {
  text-decoration: none;
  color: black;
}

.none2 {
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.bi-list {
  display: none;
  font-size: 20px;
}

@media(max-width:970px) {
  nav ul, nav button {
    display: none;
  }

  .nav {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .bi-list {
    display: block !important;
  }

  .opacinput {
    height: 72px;
    background-color: white;
    width: 100%;
  }

  .opac {
    width: 100%;

  }
}



/* Styles for the toggleable div */
.toggleDiv {
  /* width: 100%; */
  height: 100%;
  margin-top: 20px;
  padding: 20px;
  background-color: rgba(19, 11, 70, 0.25);
  border: 1px solid #787878;
  border-radius: 50px;
  color: rgb(255, 255, 255);
  text-align: center;
  margin-top: 150px;
  padding-top: 50px;

}

.toggleDiv ul {
  padding-left: 0px;
}

.toggleDiv ul li {
  font-family: "Manrope", sans-serif;
  /* color: rgb(255, 255, 255); */

  font-weight: 700;
  font-size: 16px;
  list-style: none;
  margin-bottom: 25px;
}

.toggleDiv-ops {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 100;
  top: 80px;
}

/* Navbar.css */

/* Keyframes for the slide-in animation */
@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Style for the toggle div */
.toggleDiv {

  animation: slideUp 0.5s ease-out;
  /* Apply the slide-up animation */
  z-index: 1000;
  /* Ensure it's on top */
}

.toggleDiv {
  padding: 20px;
  /* Padding for content */
}




@media(max-width:850px) {
  .banner {
    padding: 40px 20px;
  }

  .banner h2 {
    font-size: 35px;

  }
}

@media(max-width:850px) {
  .opac {
    height: 80px;
    padding-left: 20px;
    padding-right: 20px;
    gap: 20px;

  }

  .opacinput {
    height: 50px;
  }

  .opac button {
    height: 50px;
    padding-left: 15px;
    padding-right: 15px;
    width: auto;
  }
}

@media(max-width:650px) {
  .car-card {
    width: 100%;
  }

  .car-card-heading {
    width: 100%;
  }

  .car-card img {
    width: 80%;
    /* width: 100%; */
  }

  .cat-div {
    padding: 30px;
  }

  .turf-banner {
    height: 250px;
  }

  .turf-banner-div {
    Height: 250px;
  }

  .banner h2 {
    font-size: 30px;
  }

  .banner p {
    font-size: 16px;
  }

  .resister-div-left h2 {
    font-family: "Oxanium", sans-serif;
    font-size: 35px;
  }

  .resister-div-left button {
    Width:
      170px;
    Height:
      45px;
  }

  .resister {
    padding: 30px;
  }

  .game-cards-div {
    padding: 20px 0px;

  }

  .sloat-heading-div {
    padding: 20px;
  }
}

@media(max-width:900px) {
  .resister-div {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    /* justify-content: center;
  align-items: center; */

  }

  .resister-div-right {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .resister-div-right img {
    width: 50%;
  }

  .resister-div-left {
    padding-left: 25px;

  }
}

@media(max-width:950px) {
  .login-left {
    width: 40%;
  }

  .login-right {
    width: 60%;
    height: 100%;

  }
}

@media(max-width:850px) {
  .login-left {
    display: none;
  }

  .login-right {
    width: 100%;
    /* height: 100%; */

  }
}

@media(max-width:650px) {
  .login-right-div {
    padding: 50px 30px;

  }

  .login-section {
    padding: 30px;
  }

  .footer ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  .footer ul li {
    list-style: none;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    font-weight: 500;

  }
}

@media(max-width:650px) {
  .reg-banner-div {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .reg-banner-div h2 {
    font-size: 25px;

  }

  .reg-banner-div h2 span {
    font-size: 18px;
  }

  .reg-banner {
    padding: 20px;
  }

  .available-venues {
    padding: 20px;
  }

  .venue-text-heading3 {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  .venue-text h2 {
    font-size: 20px;
  }

}

.rate-div {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

@media(max-width:768px) {
  .venue-detail {
    padding: 20px;
  }

  .venue-text-heading2 {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .tab-container {
    padding: 20px;
  }

  .NaturalGrass-div {
    padding: 20px;
  }

  .slot-heading {
    padding: 20px;
  }

  .slot-heading h3 {
    font-family: "Oxanium", sans-serif;
    font-size: 20px;
    font-weight: 600;
  }

  .slot-heading p {
    font-family: "Oxanium", sans-serif;
    font-size: 12px;
    font-weight: 400;
  }

  .day-night-btn {
    padding: 0px 15px;

  }

  .time-div {
    padding: 20px;
  }

  .slotdetail-heading h3 {
    font-family: "Oxanium", sans-serif;
    font-size: 22px;
    font-weight: 500;
  }

  .slotdetail-heading h4 {
    font-family: "Oxanium", sans-serif;
    font-size: 18px;
    font-weight: 400;
  }

  .slotdetail p {
    font-family: "Oxanium", sans-serif;
    font-size: 14px;
    font-weight: 500;
  }

  .slot-card {
    width: 100%;
    height: auto;
  }

  .slot-card-heading h4 {
    font-family: "Oxanium", sans-serif;
    font-size: 18px;
    font-weight: 400;
  }

  .slot-card-heading h3 {
    font-family: "Oxanium", sans-serif;
    font-size: 18px;
    font-weight: 500;
  }

  .apply-coupon h2 {
    font-family: "Oxanium", sans-serif;
    font-size: 20px;
    font-weight: 600;
  }

  .apply-coupon h2 span {
    font-weight: 400;
    font-size: 15px;

  }

  .apply-coupon2 ul li {
    list-style: none;
    font-family: "Oxanium", sans-serif;
    font-size: 15px;
    font-weight: 400;
  }

  .cat {
    font-family: "Oxanium", sans-serif;
    font-size: 15px;
    font-weight: 600 !important;
  }

  .venue-detail {
    padding: 20px;
  }

  .venue-text-heading3 {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: start;
    align-items: start;
  }

  .venue-text-heading3 h3 {
    font-size: 20px;
    margin-top: 10px;
  }

  .venue-text-heading2-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
  }

  .tabs {
    justify-content: start;
    padding-left: 20px;
  }

  .venue-text {
    padding: 20px;
  }

  .coach-avail-heading {
    padding: 0px 20px;

  }
}

.clock {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

@media(max-width:550px) {
  .sloat-heading1 {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}

@media(max-width:650px) {
  .addtruf-ips {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .addturf-div {
    padding: 20px;
  }
}

@media(max-width:950px) {
  .slot-info {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 20px;
  }
}

@media(max-width:650px) {
  .addturf {
    padding: 15px;
  }

  .slot-info3 {
    flex-wrap: wrap;
  }

  .slot-info1 {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

@media(max-width:800px) {
  .profile {
    padding: 20px;
  }

  .pro-1 {
    display: flex;
    flex-direction: column;
  }

  .pr-box1 {
    width: 100%;
  }

  .pr-box2 {
    width: 100%;
  }
}

@media(max-width:700px) {
  .profile {
    padding: 20px;
    flex-direction: column;
  }

  .profile-left {
    width: 100%;


  }

  .profile-right {
    width: 100%;
  }

  .newprofile-tabs {
    width: 100%;
    padding: 0px;

  }

  .newprofile-tabs img {
    display: none;
  }

  .newprofile-tabs {
    display: flex;
    flex-direction: row;
    gap: 30px;
    overflow-x: auto;
  }

  .newprofile-tabs button {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-top: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;

  }

  nav {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.filters {}

.filters select {
  padding: 10px 18px;
  background-color: rgba(235, 238, 243, 1);
  border-radius: 30px;
  border: none;
  font-size: 18px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;

}

.yes {
  padding: 50px;
}

.yes p {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Oxanium", sans-serif;
  font-size: 24px;
  font-weight: 400;
  gap: 20px;
}

.payment-btn {
  background-color: rgba(243, 245, 248, 1);
  height: 73px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  gap: 100px;
}

.payment-btn p {
  font-family: "Oxanium", sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.payment-btn button {
  padding: 10px 25px;
  background-color: #31ED31;
  border-radius: 10px;
  border: none;
  color: white;
}

@media(max-width:530px) {
  .all-bookings {
    flex-direction: column;
  }

  .booking-CRUD-btns {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

  }

  .booking-CRUD-btns button {
    padding: 10px 10px;
  }
}

@media(max-width:530px) {
  .booking-timing {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.calendar-container {
  margin: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.month-text {
  font-size: 18px;
  font-family: 'Oxanium', sans-serif;
  color: #000;
}

.dates-container {
  display: flex;
  overflow-x: auto;
  padding: 0 10px;
}

.date-box {
  background-color: #F3F5F8;
  border-radius: 10px;
  padding: 10px 12px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  cursor: pointer;
}

.selected-date-box {
  background-color: #31ED31;
  color: #fff;
}

.date-text {
  color: #000;
  font-family: "Oxanium", sans-serif;
  font-size: 12px;
}

.date-number {
  color: #000;
  font-family: "Oxanium", sans-serif;
  font-size: 14px;
  margin-top: 5px;
}

.reviews-section {
  margin-top: 30px;
  font-size: 14px;
  color: #000;
  font-family: "Oxanium", sans-serif;
}


.add-review-container {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  /* max-width: 400px; */
  margin: 0 auto;
  font-family: "Oxanium", sans-serif;
}

.star-rating {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  font-family: "Oxanium", sans-serif;
}

.star {
  cursor: pointer;
  transition: color 200ms;
  font-family: "Oxanium", sans-serif;
}

textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: none;
  font-family: "Oxanium", sans-serif;
}

button {
  background-color: #31ED31;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-family: "Oxanium", sans-serif;
}



/* 
.sports-container1 {
  display: flex;
  justify-content: center;
}

.sports-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  justify-items: center;
}

.sport-btn {
  width: 200px;
  height: 150px;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  font-family: 'Oxanium', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.sport-btn img {
  height: 60px;
  width: 60px;
}

.sport-btn span {
  margin-top: 10px;
}

.sport-btn.active {
  border-color: #00c851;
  border-width: 3px;
}

.sport-btn:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
} */

.sports-container1 {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.sports-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  justify-items: center;
}

/* Default button styling */
.sport-btn {
  width: 200px;
  height: 150px;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  font-family: 'Oxanium', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.sport-btn img {
  height: 60px;
  width: 60px;
}

.sport-btn span {
  margin-top: 10px;
}

.sport-btn.active {
  border-color: #00c851;
  border-width: 3px;
}

.sport-btn:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .sports-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .sport-btn {
    width: 180px;
    height: 140px;
  }
}

@media (max-width: 768px) {
  .sports-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }

  .sport-btn {
    width: 100%;
    max-width: 300px;
    height: 130px;
  }
}

@media (max-width: 480px) {
  .sports-grid {
    /* grid-template-columns: 1fr; */
    grid-template-columns: repeat(2, 1fr);
  }

  .sport-btn {
    width: 100%;
    max-width: 260px;
    height: 120px;
    font-size: 14px;
  }

  .sport-btn img {
    height: 50px;
    width: 50px;
  }
}


.create-game {
  background-color: #F1F3F6;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  margin: auto;
  font-family: 'Oxanium', sans-serif;
}


.teams-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0;
  font-family: 'Oxanium', sans-serif;
  gap: 20px;
}

.team h2 {
  text-align: left;
  font-size: 24px;
  margin-bottom: 10px;
}

.designation-grid {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.designation-btn {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  font-family: 'Oxanium', sans-serif;
  color: #000;
  font-weight: 600;
  text-align: center;
}

.designation-btn.active {
  border-color: #00c851;
  background-color: #eaffea;
}


/* Responsive adjustments */
@media (max-width: 1200px) {
  .team h2 {
    font-size: 22px;
  }

  .designation-btn {
    font-size: 15px;
    padding: 8px;
  }
}

@media (max-width: 768px) {
  .designation-grid {
    justify-content: center;
  }

  .designation-btn {
    font-size: 14px;
    padding: 6px;
    min-width: 100px;
    /* Reduces button width on smaller screens */
  }
}

@media (max-width: 480px) {
  .teams-container {
    align-items: center;
  }

  .team h2 {
    font-size: 20px;
    text-align: center;
  }

  .designation-grid {
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }

  .designation-btn {
    width: 100%;
    max-width: 250px;
    font-size: 13px;
    padding: 5px;
  }
}



.center-btn {
  text-align: center;
  margin-top: 20px;
}

.center-btn button {
  padding: 10px 20px;
  background-color: #00c851;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-family: 'Oxanium', sans-serif;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: rgb(223, 221, 221);
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  font-family: 'Oxanium', sans-serif;
}


.modal-content input[type="text"] {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Oxanium', sans-serif;
}

.modal-content select {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Oxanium', sans-serif;
}

.modal-content label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  font-family: 'Oxanium', sans-serif;
}

.modal-content button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Oxanium', sans-serif;
}

.modal-content button:first-child {
  background-color: #00c851;
  color: white;
}

.modal-content button:last-child {
  background-color: #ccc;
  font-family: 'Oxanium', sans-serif;
}

.modal-content1 {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  text-align: center;
  font-family: 'Oxanium', sans-serif;
}

.modal-content1 input[type="text"] {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Oxanium', sans-serif;
}

.modal-content1 select {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Oxanium', sans-serif;
}

.modal-content1 label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  font-family: 'Oxanium', sans-serif;
}

.modal-content1 button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Oxanium', sans-serif;
}




.scorecard {
  width: '100%';
  margin: auto;
  font-family: Arial, sans-serif;
  background-color: #f7f9fc;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-family: Arial, sans-serif;
}

.back-button, .share-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  font-family: Arial, sans-serif;
}

.score-summary {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
  font-family: Arial, sans-serif;
}

.team-score {
  text-align: center;
  font-family: Arial, sans-serif;
}

.team-button {
  padding: 5px 15px;
  border: none;
  border-radius: 20px;
  background-color: #e0e0e0;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  font-family: Arial, sans-serif;
}

.vs {
  font-size: 20px;
  font-weight: bold;
}

.status {
  text-align: center;
  margin: 10px 0;
  font-size: 14px;
  color: #666;
  font-family: Arial, sans-serif;
}

.tabs {
  display: flex;
  justify-content: space-around;
  background-color: #e0e0e0;
  border-radius: 20px;
  margin: 15px 0;
  font-family: Arial, sans-serif;
}

.tabs button {
  flex: 1;
  padding: 10px 0;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #666;
  font-family: Arial, sans-serif;
}

.tabs .active-tab {
  background-color: #00c851;
  color: white;
  border-radius: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  font-family: Arial, sans-serif;
}

.table-header, .table-row {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #ddd;
}

.table-header {
  background-color: #e0f7e0;
  font-weight: bold;
  color: #333;
}

.player-info {
  display: flex;
  align-items: center;
}

.player-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.table-row {
  background-color: #f9f9f9;
}

.extras, .total {
  background-color: #f0f0f0;
  font-weight: bold;
  font-family: Arial, sans-serif;
}

.total {
  border-bottom: none;
}

.table {
  display: grid;
  gap: 8px;
}

.table-header, .table-row {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  gap: 10px;
}

.table-header {
  font-weight: bold;
}

.table-row p {
  margin: 0;
}

.extras, .total {
  grid-template-columns: 2fr 1fr 4fr;
}

.extras span, .total span {
  grid-column: span 2;
}

.scoreboard {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: '100%';
  font-family: Arial, sans-serif;
}

.checkbox-row {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}

.checkbox-row label {
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.checkbox-row input[type="checkbox"] {
  margin-right: 5px;
}

.number-row {
  display: flex;
  justify-content: space-around;
}

.number-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background-color: #e8ecef;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.number-button:hover {
  background-color: #d4d9dd;
}

.numberActive {
  background-color: #00c851;
  color: #fff;
}



.UndoButton {
  width: 40%;
  height: 58px;
  border-radius: 12px;
  background-color: rgba(49, 237, 49, 1);
  color: white;
  font-family: "Oxanium", sans-serif;
  font-size: 18px;
  font-weight: 700;
  border: 1px solid rgba(49, 237, 49, 1);
  margin-top: 10px;
}

@media (max-width: 1024px) {
  .header h2 {
    font-size: 22px;
  }

  .back-button, .share-button {
    font-size: 18px;
  }

  .team-button {
    font-size: 13px;
  }

  .vs {
    font-size: 18px;
  }

  .status {
    font-size: 13px;
  }

  .tabs button,
  .table-header, .table-row {
    font-size: 15px;
  }

  .checkbox-row label {
    font-size: 13px;
  }

  .number-button {
    font-size: 15px;
  }

  .UndoButton {
    font-size: 16px;
    width: 50%;
    margin-top: 6px;
  }
}

@media (max-width: 768px) {
  .header h2 {
    font-size: 20px;
  }

  .back-button, .share-button {
    font-size: 16px;
  }

  .team-button {
    font-size: 12px;
  }

  .vs {
    font-size: 16px;
  }

  .status {
    font-size: 12px;
  }

  .tabs button,
  .table-header, .table-row {
    font-size: 14px;
  }

  .checkbox-row label {
    font-size: 12px;
  }

  .number-button {
    font-size: 14px;
  }

  .UndoButton {
    font-size: 14px;
    width: 60%;
    margin-top: 5px;
  }
}

@media (max-width: 576px) {
  .header h2 {
    font-size: 18px;
  }

  .back-button, .share-button {
    font-size: 14px;
  }

  .team-button {
    font-size: 11px;
  }

  .vs {
    font-size: 14px;
  }

  .status {
    font-size: 11px;
  }

  .tabs button,
  .table-header, .table-row {
    font-size: 13px;
  }

  .checkbox-row label {
    font-size: 11px;
  }

  .checkbox-row input[type="checkbox"] {
    margin-top: 5px;
  }

  .number-button {
    font-size: 13px;
  }

  .UndoButton {
    font-size: 12px;
    width: 80%;
    margin-top: 2px;
  }
}


.modal-content {
  background-color: rgb(239, 236, 236);
  padding: 20px;
  border-radius: 8px;
  width: 600px;
  max-width: 100%;
  max-height: 80vh;
  overflow-y: auto;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Oxanium', sans-serif;
}

.bookingPadding {
  padding-left: 220px;
  padding-right: 220px;
}

@media (max-width: 1200px) {
  .bookingPadding {
    padding-left: 180px;
    padding-right: 180px;
  }
}

@media (max-width: 992px) {
  .bookingPadding {
    padding-left: 150px;
    padding-right: 150px;
  }
}

@media (max-width: 768px) {
  .bookingPadding {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media (max-width: 576px) {
  .bookingPadding {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 400px) {
  .bookingPadding {
    padding-left: 20px;
    padding-right: 20px;
  }
}


.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown .dropdown-content {
  display: block;
  position: absolute;
  background-color: #fff;
  min-width: 100%;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  padding: 10px;
  margin-top: 5px;
}

.dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  border-radius: 5px;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
  color: #007bff;
}

.modal-overlay {
  position: fixed;
  z-index: 10;
  inset: 0;
  overflow-y: auto;
  padding: 0 20px;
}

.modal-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
}

.modal-background {
  /* position: fixed; */
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-spacer {
  display: inline-block;
  height: 100vh;
  vertical-align: middle;
}

.modal-panel {
  display: inline-block;
  max-width: 921px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(10%);
  transition: all 0.3s ease;
}

.modal-content12 {
  padding: 20px 17px;
}

.search-container {
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 11px 15px;
  margin-bottom: 13px;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
}

.search-input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 12px;
  color: black;
}

.location-detect {
  display: flex;
  align-items: center;
  gap: 9px;
  padding-bottom: 26px;
}

.detect-location-text {
  color: #A264F4;
  font-size: 14px;
  cursor: pointer;
}

.popular-cities-text {
  color: #000;
  font-size: 14px;
  text-align: center;
  padding-bottom: 20px;
}

/* .popular-cities {
  display: flex;
  justify-content: center;
  gap: 25px;
}

.city-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.city-image {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  cursor: pointer;
} */

.popular-cities {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 25px;
  justify-content: center;
}

.city-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.city-image {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  cursor: pointer;
}

.city-name {
  color: #000;
  font-size: 14px;
  text-align: center;
}


.checkbox-rownew {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.checkbox-containernew {
  flex: 1 1 45%;
  display: flex;
  align-items: center;
}

.delete-btn {
  background-color: #ff4d4d;
  /* Default red color for active button */
  color: white;
  cursor: pointer;
}

.delete-btn.disabled {
  background-color: #d3d3d3;
  /* Grey color for disabled button */
  color: #a1a1a1;
  /* Lighter text color */
  cursor: not-allowed;
}

.no-institute-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  font-size: 20px;
}

.review-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.review-header {
  display: flex;
  align-items: center;
  gap: 20px;
}

.user-profile-img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin-right: 20px;
}

.user-name {
  font-weight: bold;
  font-size: 20px;
  font-family: "Oxanium", sans-serif;
}

.rating {
  display: flex;
  align-items: center;
}

.star {
  color: #FFD700;
  margin-right: 5px;
}

.review-text {
  margin-top: 10px;
  font-size: 20px;
  color: #555;
  font-family: "Oxanium", sans-serif;
}

@media (max-width: 1200px) {
  .review-header {
    gap: 15px;
  }

  .user-name {
    font-size: 18px;
  }

  .review-text {
    font-size: 18px;
  }

  .user-profile-img {
    height: 80px;
    width: 80px;
  }
}

@media (max-width: 768px) {
  .review-header {
    gap: 10px;
  }

  .user-name {
    font-size: 16px;
  }

  .review-text {
    font-size: 16px;
  }

  .user-profile-img {
    height: 70px;
    width: 70px;
  }
}

@media (max-width: 480px) {
  .review-header {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }

  .user-name {
    font-size: 14px;
  }

  .review-text {
    font-size: 14px;
  }

  .user-profile-img {
    height: 60px;
    width: 60px;
  }
}

.bookcoach {
  width: 230px;
  height: 60px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 14px;
  font-family: 'Oxanium', sans-serif;
  margin-right: 10px;
}

@media (max-width: 1200px) {
  .bookcoach {
    width: 200px;
    font-size: 18px;
    margin-right: 8px;
  }
}

@media (max-width: 768px) {
  .bookcoach {
    width: 100%;
    height: 50px;
    font-size: 18px;
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .bookcoach {
    width: 100%;
    height: 50px;
    font-size: 16px;
    margin-right: 0;
  }
}


.policy-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: "Roboto", sans-serif;
}

.policy-section {
  margin-bottom: 30px;
  text-align: left;
}

.policy-section h2 {
  color: rgba(49, 237, 49, 1);
  font-size: 24px;
  margin-bottom: 15px;
}

.policy-item h3 {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

.policy-item ul, .policy-item ol {
  margin: 10px 0 20px 0;
  padding: 0;
  list-style-type: none;
}

.policy-item li, .policy-item p {
  margin: 5px 0 15px;
}

@media (max-width: 768px) {
  .policy-container {
    padding: 15px;
  }

  .policy-section h2 {
    font-size: 20px;
  }

  .policy-item h3 {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .policy-container {
    padding: 10px;
  }

  .policy-section h2 {
    font-size: 18px;
  }

  .policy-item h3 {
    font-size: 16px;
  }
}

.privacy-policy-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: "Roboto", sans-serif;
  text-align: center;
}

.policy-section {
  margin-bottom: 30px;
  text-align: left;
}

.policy-section h2 {
  color: rgba(49, 237, 49, 1);
  font-size: 24px;
  margin-bottom: 15px;
}

.policy-item h3 {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

.policy-section ul li {
  list-style: none;
}

.policy-item ul, .policy-item ol {
  margin: 10px 0 20px;
  padding: 0;
  list-style: none;
}

.policy-item li, .policy-item p {
  margin: 5px 0 15px;
}

@media (max-width: 768px) {
  .privacy-policy-container {
    padding: 15px;
  }

  .policy-section h2 {
    font-size: 20px;
  }

  .policy-item h3 {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .privacy-policy-container {
    padding: 10px;
  }

  .policy-section h2 {
    font-size: 18px;
  }

  .policy-item h3 {
    font-size: 16px;
  }
}


/* General styling for the Terms page */
.terms-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: "Roboto", sans-serif;
}

.terms-header {
  text-align: center;
}

.terms-header h1 {
  color: #333;
  font-size: 32px;
  margin-bottom: 20px;
}

.terms-section {
  margin-bottom: 40px;
}

.terms-section h2 {
  color: rgba(49, 237, 49, 1);
  font-size: 24px;
  margin-bottom: 20px;
}

.policy-item h3 {
  font-size: 22px;
  color: #333;
  margin-bottom: 10px;
}

.policy-item ul {
  margin: 10px 0;
  padding: 0;
  list-style-type: none;
}

.policy-item li {
  margin: 5px 50px 10px;
}

.terms-footer {
  text-align: center;
  font-size: 14px;
  color: #777;
}

/* Responsive styling */
@media (max-width: 768px) {
  .terms-container {
    padding: 15px;
  }

  .terms-header h1 {
    font-size: 28px;
  }

  .terms-section h2 {
    font-size: 22px;
  }

  .policy-item h3 {
    font-size: 20px;
  }

  .policy-item li {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .terms-container {
    padding: 10px;
  }

  .terms-header h1 {
    font-size: 24px;
  }

  .terms-section h2 {
    font-size: 20px;
  }

  .policy-item h3 {
    font-size: 18px;
  }

  .policy-item li {
    font-size: 14px;
  }

  .terms-footer {
    font-size: 12px;
  }

}

.alphabet-list {
  list-style-type: upper-alpha;
  margin: 0;
  padding-left: 20px;
}

.alphabet-list li::marker {
  font-weight: bold;
  margin-right: 10px;
}

.alphabet-list li {
  margin-bottom: 10px;
}

.TermsandConditionsdiv ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style-type: none;
  gap: 10px;
  justify-content: center;
}

.TermsandConditionsdiv li {
  flex: 1 1 auto;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .TermsandConditionsdiv li {
    flex: 1 1 100%;
  }
}

.footer-text {
  margin-bottom: 20px;
  font-size: 14px;
  color: #555;
}

.success-booking-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  min-height: calc(100vh - 200px); /* Adjust for Navbar and Footer height */
  background-color: #f8f9fa;
}

.success-icon {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

.success-heading {
  font-size: 2rem;
  color: #28a745;
  margin-bottom: 20px;
  font-weight: bold;
}

.back-to-home-btn {
  max-width: 200px;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}



/* Media Queries for Responsive Design */

@media (max-width: 768px) {
  .success-heading {
      font-size: 1.5rem;
  }

  .success-icon {
      width: 80px;
  }

  .back-to-home-btn {
      padding: 8px 16px;
      font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .success-heading {
      font-size: 1.2rem;
  }

  .success-icon {
      width: 60px;
  }

  .back-to-home-btn {
      padding: 6px 12px;
      font-size: 0.8rem;
  }
}


.failed-booking-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  min-height: calc(100vh - 200px); /* Adjust for Navbar and Footer height */
  background-color: #f8d7da;
}

.failed-icon {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

.failed-heading {
  font-size: 2rem;
  color: #dc3545;
  margin-bottom: 20px;
  font-weight: bold;
}

.retry-btn {
  max-width: 200px;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #dc3545;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}


/* Media Queries for Responsive Design */

@media (max-width: 768px) {
  .failed-heading {
      font-size: 1.5rem;
  }

  .failed-icon {
      width: 80px;
  }

  .retry-btn {
      padding: 8px 16px;
      font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .failed-heading {
      font-size: 1.2rem;
  }

  .failed-icon {
      width: 60px;
  }

  .retry-btn {
      padding: 6px 12px;
      font-size: 0.8rem;
  }
}
